<template>
  <div class="main">
      <div class="login-form">
        <h1>请登录</h1>
        <div class="head">
          <img src="@/assets/images/user.png" alt="" />
        </div>
        <form>
          <input
            type="text"
            class="text"
            placeholder="用户名"
          />
          <input
            type="password"
            placeholder="密码"
          />
          <div class="submit">
            <input type="submit" value="登录" />
          </div>
          <!-- <p><a href="#">Forgot Password ?</a></p> -->
        </form>
      </div>
      <!--//End-login-form-->
      <!-----start-copyright---->
      <div class="copy-right">
        <p>
          版权所有 ©深圳市瑞麟数字科技控股有限公司
          <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2021043893号-6</a>
        </p>
      </div>
      <!-----//end-copyright---->
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.main {
    font-size: 14px;
}
</style>
